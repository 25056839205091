import { Injectable } from '@angular/core';
import { DepositsFiatService } from '@libs/app.services/wallet/deposits.fiat.service';
import { IDeposit } from '@libs/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, tap } from 'rxjs/operators';
@Injectable()
export class WalletEffects {
  private pendingDeposits;
  constructor(
    private actions: Actions,
    private service: DepositsFiatService,
  ) {
    this.pendingDeposits = createEffect(() =>
      this.actions.pipe(
        ofType('[wallet] Load pending deposits'),
        exhaustMap(() =>
          this.service.pendingDeposits().pipe(
            map((deposits: IDeposit[]) => ({ type: '[User Wallet] Pending deposits', pendingDeposits: deposits })),
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
  }
}
