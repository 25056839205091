import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { delay, Observable, of } from 'rxjs';
import { StoreWalletService } from '@libs/app.services/store/store-wallet.service';

@Injectable({
  providedIn: 'root',
})
export class PendingDepositsResolver implements Resolve<boolean> {
  constructor(private storeWalletService: StoreWalletService) {}

  resolve(): Observable<boolean> {
    this.storeWalletService.loadPendingDeposits();
    return of(true).pipe(delay(1000));
  }
}
