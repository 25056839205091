<div class="wrapper">
  @if (config$ | async) {
    <router-outlet></router-outlet>
    @if ((selectAsideRight$ | async) || (selectAsideLeft$ | async)) {
    } @else {
      @if (tidioAvailable()) {
        <app-tidio-button />
      }
    }
  } @else {
    <div class="loading">
      <div class="spinner">
        <svg width="72" height="72" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <style>
            rect {
              fill: var(--primary-color);
            }

            .spinner_9y7u {
              animation: spinner_fUkk 2.4s linear infinite;
              animation-delay: -2.4s;
            }
            .spinner_DF2s {
              animation-delay: -1.6s;
            }
            .spinner_q27e {
              animation-delay: -0.8s;
            }
            @keyframes spinner_fUkk {
              8.33% {
                x: 13px;
                y: 1px;
              }
              25% {
                x: 13px;
                y: 1px;
              }
              33.3% {
                x: 13px;
                y: 13px;
              }
              50% {
                x: 13px;
                y: 13px;
              }
              58.33% {
                x: 1px;
                y: 13px;
              }
              75% {
                x: 1px;
                y: 13px;
              }
              83.33% {
                x: 1px;
                y: 1px;
              }
            }
          </style>
          <rect class="spinner_9y7u" x="1" y="1" rx="1" width="10" height="10" />
          <rect class="spinner_9y7u spinner_DF2s" x="1" y="1" rx="1" width="10" height="10" />
          <rect class="spinner_9y7u spinner_q27e" x="1" y="1" rx="1" width="10" height="10" />
        </svg>
      </div>
    </div>
  }
</div>
<div class="toast">
  <app-toast />
</div>
<div class="modal">
  <app-modal />
</div>
<app-router-loader />
