import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@libs/app.services/account/user.service';
import { UserStoreService } from '@libs/app.services/store/user-store.service';
@Injectable({
  providedIn: 'root',
})
export class UserDocsResolver implements Resolve<boolean> {
  constructor(
    private userService: UserService,
    private userStoreService: UserStoreService,
    private router: Router,
  ) {}

  resolve(): Observable<boolean> {
    const canActivate = this.userStoreService.stateData() > 1;
    return canActivate ? of(true) : this.toast();
  }
  toast() {
    this.userService.msgWithdrawDocsCanntActivate();
    this.router.navigateByUrl('/account/me');
    return of(false);
  }
}
