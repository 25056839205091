import { createAction, createReducer, on } from '@ngrx/store';
import { assign, cloneDeep, pick, take } from 'lodash';
import {
  addCategorySearchGames,
  addGame,
  addGamesActivities,
  addGamesActivity,
  addGamesCategories,
  addGamesCategory,
  addGamesProviders,
  addGridGameSession,
  addSession,
  removeGridGameSession,
  removeSession,
  setDesktop,
  setFullScreen,
  setGame,
  setGameScreenLayout,
  setSelectedIndex,
  setShowGamesTree,
  toggleFullScreen,
  toggleGameType,
  toggleShowGamesTree,
} from './actions';
import { GAME_SCREEN_LAYOUT, GAME_TYPE, IGameState, SCREEN_GRID_TYPE } from './model';
import { IGameProvider, ILobbySection } from '@libs/interfaces';
import { LOBBY_SECTIONS } from '@libs/helper/casino/game-cats.constants';

const initialState: Readonly<IGameState> = {
  grid_game_sessions: [null, null, null, null],
  selectedIndex: 0,
  game_screen_layout: GAME_SCREEN_LAYOUT.SINGLE,
  categories: LOBBY_SECTIONS,
  categorySearchGames: [],
  fullscreen: false,
  gamesProviders: [],
  activity: [],
  desktop: SCREEN_GRID_TYPE.DESKTOP,
  game_type: GAME_TYPE.REAL,
  showGamesTree: false,
  game: null,
};
export const sessionGameReducer = createReducer(
  initialState,
  on(addSession, (state, { session }) => assign({}, state, { session: session })),
  on(addGame, (state, { game }) => assign({}, state, { game: game })),
  on(addGamesProviders, (state, { gamesProviders }) =>
    assign({}, state, {
      gamesProviders: gamesProviders.map((p: IGameProvider) =>
        assign({}, p, {
          image_icon: `https://lotodobicho.com/gallery/game-providers/short/${p._id}.png`,
          image: `https://lotodobicho.com/gallery/game-providers/long/${p._id}.png`,
        }),
      ),
    }),
  ),
  on(addCategorySearchGames, (state, { categorySearchGames }) =>
    assign({}, state, { categorySearchGames: categorySearchGames }),
  ),
  on(removeSession, (state, { index }) => {
    const newSessions = cloneDeep(state.grid_game_sessions);
    newSessions[index] = null;
    return assign({}, state, { grid_game_sessions: newSessions });
  }),
  on(toggleGameType, state =>
    assign({}, state, { game_type: state.game_type == GAME_TYPE.REAL ? GAME_TYPE.FUN : GAME_TYPE.REAL }),
  ),
  on(toggleFullScreen, state => assign({}, state, { fullscreen: !state.fullscreen })),
  on(setFullScreen, (state, { fullscreen }) => assign({}, state, { fullscreen: fullscreen })),
  on(addGamesCategories, (state, { categories }) =>
    assign({}, state, {
      categories: state.categories.map((cat: ILobbySection) =>
        assign({}, cat, { games: categories.find(c => c.category == cat.section)?.games || [] }),
      ),
    }),
  ),
  on(addGamesCategory, (state, { games, section }) => {
    const cats = [...state.categories];
    const cat = cats.find((c: ILobbySection) => c.section == section);
    const catReplace = assign({}, cat, { games: games });
    return assign({}, state, { categories: splitArrayElementAndReplace(cats, cat, catReplace) });
  }),
  on(addGamesActivities, (state, { activity }) => assign({}, state, { activity: activity })),
  on(addGamesActivity, (state, { activity }) => {
    if (!state.activity.find(a => a._id == activity._id))
      return assign({}, state, { activity: take([activity, ...state.activity], 50) });
    else return state;
  }),
  on(setGameScreenLayout, (state, { game_screen_layout }) =>
    assign({}, state, { game_screen_layout: game_screen_layout }),
  ),
  on(addGridGameSession, (state, { index, gridGameSession }) => {
    const newGridGameSessions = [...state.grid_game_sessions];
    newGridGameSessions[index] = gridGameSession
      ? assign({}, gridGameSession, {
          game: pick(gridGameSession.game, [
            'external_id',
            'name',
            'image',
            'category',
            'provider',
            'aggregator',
            'demo_game_support',
          ]),
        })
      : null;
    return assign({}, state, { grid_game_sessions: newGridGameSessions });
  }),
  on(removeGridGameSession, (state, { index }) => {
    const newGridGameSessions = [...state.grid_game_sessions];
    newGridGameSessions[index] = null;
    return assign({}, state, { grid_game_sessions: newGridGameSessions });
  }),
  on(setDesktop, (state, { desktop }) => assign({}, state, { desktop: desktop })),

  on(setSelectedIndex, (state, { selectedIndex }) => assign({}, state, { selectedIndex: selectedIndex })),
  on(setShowGamesTree, (state, { showGamesTree }) => assign({}, state, { showGamesTree: showGamesTree })),
  on(toggleShowGamesTree, state => assign({}, state, { showGamesTree: !state.showGamesTree })),
  on(setGame, (state, { game }) => assign({}, state, { game: game })),
);
export const splitArrayElementAndReplace = (arr, element, elementReplace) => {
  const index = arr.reduce((acc, current, i) => (current === element ? i : acc), undefined);
  return [...arr.slice(0, index), elementReplace, ...arr.slice(index + 1)];
};
