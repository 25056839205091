import { createReducer, on } from '@ngrx/store';
import {
  addBetTicket,
  addBookmarkJob,
  addBookmarks,
  addTicketBookmarks,
  removeBetTicket,
  removeTicketBookmarks,
  resetTicket,
} from './actions';
import { assign, findIndex } from 'lodash';
import { RemoveArrayElement } from '@libs/helper/helper';
import { IBookmarksState } from './model';
import { IUserTicket } from '@libs/interfaces';

const initialState: Readonly<IBookmarksState> = {
  ticket: null,
  selected: null,
  bookmarks: [],
};
export const bookmarksReducer = createReducer(
  initialState,
  on(resetTicket, (state, { ticket }) => assign({}, state, { ticket: ticket })),
  on(addBetTicket, (state, { bet }) =>
    assign({}, state, { ticket: assign({}, state.ticket, { bets: [bet, ...state.ticket.bets] }) }),
  ),
  on(removeBetTicket, (state, { bet }) =>
    assign({}, state, { ticket: assign({}, state.ticket, { bets: RemoveArrayElement(state.ticket.bets, bet) }) }),
  ),
  on(addBookmarks, (state, { bookmarks }) => assign({}, state, { bookmarks: bookmarks })),
  on(addTicketBookmarks, (state, { ticket }) => assign({}, state, { bookmarks: [ticket, ...state.bookmarks] })),
  on(removeTicketBookmarks, (state, { ticket }) =>
    assign({}, state, { bookmarks: RemoveArrayElement(state.bookmarks, ticket) }),
  ),
  on(addBookmarkJob, (state, { ticket }) => {
    const bookmarks = [...state.bookmarks];
    const index = findIndex(bookmarks, (t: IUserTicket) => t._id === ticket._id);
    const left = bookmarks.slice(0, index);
    const right = bookmarks.slice(index + 1);
    return assign({}, state, { bookmarks: [...left, ticket, ...right] });
  }),
);
