import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ResourcesService } from '@libs/app.services/landing/resources.service';

@Injectable({
  providedIn: 'root',
})
export class BichoResolver implements Resolve<boolean> {
  constructor(private resourcesService: ResourcesService) {}

  resolve(): Observable<boolean> {
    return this.resourcesService.bicho();
  }
}
